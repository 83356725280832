import React from "react";
import {withPrefix} from "gatsby";
import { Row, Col } from 'react-grid-system';

import Layout from "../layouts";
import SEO from "../components/Seo";
import MediaText from '../components/MediaText';
import Section from "../components/Section";
import Heading from "../components/Heading";
import {ProfileCard} from "../components/Card";

import {startups} from '../../data/data';

const Startups = () => (
  <Layout>
    <SEO title="Startups"/>

    <Section>
      <MediaText {...startups.section1} />
    </Section>

    <Section>
      <Col>
        <div>
          <img src={withPrefix("images/ASSETS_v2.jpg")} style={{width: '100%', marginBottom: '24px'}} alt=""/>

          <Heading>Money20/20 Europe Startup Judging Panel</Heading>

          <Row>
            {startups.profiles.map((profile, index) => (
              <Col md={3} sm={6} key={index}>
                <ProfileCard {...profile}/>
              </Col>
            ))}
          </Row>

          <Heading>Q&A</Heading>

          <h3>What's the timeline?</h3>
          <p>We want to hear from startups who cover a broad range of industry sectors from P2P lending to financial inclusion, mobile wallets, security & fraud and much more. To qualify for the academy, your startup must:</p>

          <ul>
            <li>Have raised less than €3M in equity funding</li>
            <li>Been incorporated for less than 5 years</li>
            <li>Be a first-time attendee of Money20/20 Europe</li>
          </ul>
          <p>If you're not sure if you qualify, feel free to still apply and we will be in touch. Or you can contact us at <a href="mailto:europe@money2020.com">europe@money2020.com</a> to find out more</p>

          <h3>What's the timeline?</h3>
          <ul>
            <li>Applications will close on 29 March 2019</li>
            <li>You will be notified if you have been successfully selected to take part in the 2019 Startup Academy in April 2019</li>
            <li>In April 2019, we'll also be inviting our 2019 Startup Academy cohort to upgrade to an exhibition space in Startup City, located at the entrance to the event</li>
            <li>We will announce which startups from the cohort have been successfully selected for our Startup Pitch line up at the end of April 2019. This year, companies featured in our renowned Startup Pitch will be solely selected from our Academy cohort</li>
          </ul>
        </div>
      </Col>
    </Section>

  </Layout>
);

export default Startups
