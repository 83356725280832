import React from 'react';
import styles from './heading.module.scss';

function Heading(props) {
  return (
    <h2 className={styles.heading}>
      {props.children}
    </h2>
  )
}

export default Heading;